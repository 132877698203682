import { subDays } from "date-fns";
import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { svgImages } from "../../assets/images";
import { AlternateSection } from "../../components/AlternateSection";
import { Button } from "../../components/Button";
import { FlashBanner } from "../../components/FlashBanner";
import { Loading } from "../../components/Loading";
import { OrderSummary } from "../../components/OrderSummary";
import { Page } from "../../components/Page";
import { Text } from "../../components/Text";
import { theme } from "../../components/theme";
import { WithAuth } from "../../components/WithAuth";
import config from "../../config";
import { useAppState } from "../../contexts";
import {
  removeProductFromOrder,
  updateOrCreateProductFromPreOrder,
  useCart,
  useProcessingOrders,
} from "../../hooks/Orders";
import { useSubscription } from "../../hooks/Subscriptions";
import { useUser } from "../../hooks/Users";
import { TProduct } from "../../types/Product";
import { formatCurrency } from "../../utils/i18n";
import { OrderStatus } from "./OrderStatus";
import { Product } from "./Product";
import { CarouselProducts } from "./../../components/CarousellProducts/CarouselProducts";
import { usePromoProducts } from "../../hooks/Products";
import { useNextDeliveries } from "../../hooks/NextDeliveries";

export const Cart: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { locale } = useAppState();
  const { data: user } = useUser();
  const { data: subscription } = useSubscription();
  const { data: nextDelivery } = useNextDeliveries();
  const { data: promoProducts = [], refetch: refetchPromoProduct } =
    usePromoProducts();

  const {
    data: order,
    refetch: refetchOrder,
    isLoading: isLoadingOrder,
  } = useProcessingOrders();
  const {
    data: cart,
    refetch: refetchCart,
    isLoading: isLoadingCart,
  } = useCart(order?.oid);
  const products = order?.products || [];
  useEffect(() => {
    if (subscription?.status === "cancel") {
      return history.push("/dashboard");
    }
  }, [subscription]);

  const redirectTo = (path: string) => () =>
    history.push({ pathname: path, state: { prevPath: "/dashboard" } });

  const onSelectProduct = (product: TProduct) =>
    history.push({
      pathname: `/products/${product.pid}`,
      state: { prevPath: "/cart" },
    });

  const onRemoveProduct = (product: TProduct) => {
    if (!user || !product?.pid) {
      return;
    }
    if (!order || !order?.processing_date) return;
    removeProductFromOrder({
      uid: user.uid,
      pid: product.pid,
      oid: order.oid,
    }).then(() => {
      refetchPromoProduct();
      refetchOrder();
      refetchCart();
    });
  };

  const onKeepProduct = (product: TProduct) => {
    if (!user || !product?.pid) {
      return;
    }
    updateOrCreateProductFromPreOrder({
      pid: product.pid,
      keep: !product.keep,
      quantity: product.quantity,
    }).then(() => {
      refetchOrder();
      refetchPromoProduct();
    });
  };

  const populateDate = nextDelivery
    ? subDays(new Date(nextDelivery?.next_processing_date), 2)
    : null;

  const subtotal = cart?.subtotal_price || 0;

  const productss = promoProducts.map((p) => {
    const productFound = products.find((p1) => p1.id === p.id);

    if (productFound) {
      return {
        ...productFound,
      };
    }
    return { ...p };
  });

  return (
    <WithAuth>
      <Page centerX centerY backgroundColor={theme.colors.base.background}>
        {isLoadingOrder && <Loading />}
        {isLoadingCart && <Loading />}

        {subtotal > 0 && subtotal < config.MIN_ORDER_PRICE && (
          <FlashBanner times={5} type="warning">
            {t("Cart.minimumNotReach", {
              minPrice: formatCurrency(locale, config.MIN_ORDER_PRICE / 100),
            })}
          </FlashBanner>
        )}

        <FlashBanner times={5} type="yellow" fix>
          {t("Cart.freeShipping")}
        </FlashBanner>

        <AlternateSection>
          <GlobalWrapper>
            <CartWrapper>
              <OrderStatus />
              {isMobile && (
                <>
                  <ActionButton to="/next-cart" primary center>
                    {t("Cart.go.next.cart")}
                    <SpanCart>🧺</SpanCart>
                  </ActionButton>
                  <ActionButton to="/products" secondary center>
                    {t("Cart.add.products")}
                  </ActionButton>
                </>
              )}

              {subscription?.status === "active" && (
                <>
                  {products.length > 0 ? (
                    <ProductsWrapper>
                      {products.map((product: TProduct) => {
                        return (
                          <Product
                            isPreOrder={order?.status === "open" ? false : true}
                            key={product.id}
                            product={product}
                            quantity={product.quantity}
                            order={order}
                            onSelectProduct={onSelectProduct}
                            onRemoveProduct={onRemoveProduct}
                            onKeepProduct={onKeepProduct}
                          />
                        );
                      })}
                    </ProductsWrapper>
                  ) : (
                    <EmptyWrapper>
                      <svgImages.emptyCart />
                      {populateDate && (
                        <>
                          <EmptyText size={"lg"}>
                            {t("Cart.to.be.filled", {
                              date: new Intl.DateTimeFormat(locale, {
                                month: "2-digit",
                                day: "2-digit",
                                year: "numeric",
                              }).format(populateDate),
                            })}
                          </EmptyText>
                          <ActionButton to="/next-cart" secondary center>
                            {t("Cart.go.next.cart")} <SpanCart>🧺</SpanCart>
                          </ActionButton>
                        </>
                      )}
                    </EmptyWrapper>
                  )}
                </>
              )}
              {!!order && order.status === "open" && isMobile && (
                <ActionButton to="/products" primary>
                  {t("Cart.add.products")}
                </ActionButton>
              )}
            </CartWrapper>
            {subscription?.status === "active" && (
              <SummaryWrapper>
                {!isMobile && (
                  <>
                    <ActionButton to="/next-cart" secondary center>
                      {t("Cart.go.next.cart")} <SpanCart>🧺</SpanCart>
                    </ActionButton>
                    <ActionButton to="/products" primary center>
                      {t("Cart.add.products")}
                    </ActionButton>
                  </>
                )}
                <OrderSummary
                  onModifyPlans={redirectTo("/account/plans")}
                  onModifyDelivery={redirectTo("/account/delivery")}
                  showCreationDate={false}
                  showDeliveryPrice={true}
                  showAction={false}
                />
              </SummaryWrapper>
            )}
          </GlobalWrapper>

          <div>
            <Heading>
              <h2>{t("PromoProducts")}</h2>
            </Heading>
            {productss && productss.length > 0 && (
              <CarouselProducts
                order={order}
                isPreOrder={order?.status === "open" ? false : true}
                products={productss}
                withBannerProduct={true}
              />
            )}
          </div>
        </AlternateSection>
      </Page>
    </WithAuth>
  );
};

const GlobalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: ${theme.spacing[4]};

  @media (min-width: ${theme.breakpoints.sm}) {
    flex-direction: row;
  }
`;

const Heading = styled.div`
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;
const SpanCart = styled.span`
  padding-left: ${theme.spacing[0.5]};
`;

const CartWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  @media (min-width: ${theme.breakpoints.sm}) {
    margin-right: ${theme.spacing[2]};
  }
`;

const SummaryWrapper = styled.div`
  width: 350px;
  display: flex;
  flex-direction: column;
  margin-top: ${theme.spacing[3]};

  @media (min-width: ${theme.breakpoints.sm}) {
    margin-top: 0;
  }
`;

const ProductsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${theme.spacing[1]};
`;

const ActionButton = styled(Button)`
  width: 100%;

  margin-bottom: ${theme.spacing[1]};
  text-align: center;

  @media (min-width: ${theme.breakpoints.sm}) {
    width: 340px;
  }
`;

const EmptyWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: center;
  padding: ${theme.spacing[2]} ${theme.spacing[4]};

  svg {
    width: 100%;
    height: auto;
    margin-bottom: ${theme.spacing[2]};
  }

  @media (min-width: ${theme.breakpoints.sm}) {
    width: 350px;
    padding: ${theme.spacing[2]} 0;
  }
`;

const EmptyText = styled(Text)`
  font-size: ${theme.font.size.mdx};
  line-height: ${theme.font.size.xl};
  color: ${theme.colors.base.dark};
  text-align: center;
  padding-bottom: ${theme.spacing[1]};

  @media (min-width: ${theme.breakpoints.sm}) {
    font-size: ${theme.font.size.lg};
    line-height: ${theme.font.size.xl};
    text-align: left;
  }
`;
